import React from 'react';
import styled from 'styled-components';

//logo
import logo from '../assets/logo.jpg';


const Nav = styled.nav`
    border-bottom: solid 1px #e2e4dc;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 2;
    #logo{
        max-width: 60px;
    }
`;


const Navbar = () => {

    return (
        <Nav>
            <img id='logo' src={logo} alt='Oxbutts logo' />
        </Nav>
    )
};

export default Navbar;