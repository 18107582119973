import React, { useRef } from "react";
import emailjs from 'emailjs-com';
import styled from "styled-components/macro";

//background photo
import dog from '../assets/about.webp'


const ContactForm = () => {
    const form = useRef();
    const sendEmail = async (e) => {
        e.preventDefault();
        try{
            const mail = await emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_USER_ID);
        } catch (err){
            console.log(err)
        }
        e.target.reset();
    };

  return (
    <Style>
        <div className='wrapper'>
            <div className='formDescription'>
                <h1>Contact Us</h1>
                <p>If you'd like to ask us a question you can get in touch by email or phone.</p>
                <p>To get in touch by email please fill in the form and we'll get back to you as soon as we can.</p>
            </div>
            <div className='form'>
                <form ref={form} onSubmit={sendEmail}>
                    <div className='field'>
                        <label htmlFor="name">Name:</label>
                        <input type="text" id="name" name="user_name" required />
                    </div>
                    <div className='field'>
                        <label htmlFor="subject">Subject:</label>
                        <input type="text" id="subject" name="subject" required />
                    </div>
                    <div className='field'>
                        <label htmlFor="subject">Phone number:</label>
                        <input type="text" id="number" name="number" required />
                    </div>
                    <div className='field'>
                        <label htmlFor="email">Email:</label>
                        <input type="email" id="email" name="user_email" required />
                    </div>
                    <div className='field' id='last'>
                        <label htmlFor="message">Message:</label>
                        <textarea id="message" name="message" required />
                    </div>
                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
    </Style>
  );
};

const Style = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    min-height: 85vh;
    z-index: 0;
    &::after{
        content: "";
        background: url(${dog});
        background-size: cover;
        background-position: bottom;
        opacity: 0.4;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1; 
    }
    .wrapper{
        width: 80%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
    }
    .formDescription{
        padding: 20px;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 330px;
        align-self: flex-start;
        h1{
            position: relative;
            margin-top: 20px;
            font-size: 30px;
            text-align: center;
        }
        p{
            margin-top: 20px;
        }
    }
    .form{
        padding: 20px;
        width: 300px;
    }
    form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
        #last{
            margin-bottom: 20px;
        }
        .field{
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 20px;
            label{
                font-weight: bold;
            }
            input{
                width: 250px;
                height: 40px;
                margin-top: 5px;
                border-radius: 8px;
                border : solid 1px black;
            }
            textarea{
                width: 250px;
                height: 150px;
                margin-top: 5px;
                border-radius: 8px;
                border : solid 1px black;
            }
        }
        button{
            height: 40px;
            min-width: 70px;
            margin-top: 20px;
            border-radius: 8px;
            border : solid 1px black;
            background-color: white;
            font-weight: bold;
        }
    }
`;

export default ContactForm;