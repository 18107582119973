import React from 'react';
import styled from 'styled-components';

const Map = () => {
    return(
        <Style>
            <div className='mapWrapper' dangerouslySetInnerHTML={{ __html: "<iframe class='map' src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2459.2049982949216!2d-2.051654284262584!3d51.94845308617992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48711c267f35a0b7%3A0x51d31de8a24ddcdf!2sOxbutts%20Pet%20%26%20Stable%20Food%20Supplies!5e0!3m2!1sen!2suk!4v1632309685429!5m2!1sen!2suk'  allowfullscreen='' loading='lazy'/>" }}>
            </div>
        </Style>
    )
};

const Style = styled.div`
    background-color: #EBEFBF;
    .mapWrapper{
        padding: 20px;
        .map{
            border: solid 2px #c4c4c4;
            width: 100%;
            height: 400px;
        }   
    }
`;

export default Map;