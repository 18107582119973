import styled from "styled-components";

//components
import Navbar from "./components/Navbar";
import Landing from "./components/Landing";
import Mail from './components/Mail';
import Map from "./components/Map";
import Footer from "./components/Footer";

//style
const Div = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=PT+Sans&display=swap');
  font-family: 'PT Sans', sans-serif;
`;


function App() {
  return (
    <Div >
      <Navbar />
      <Landing />
      <Mail />
      <Map />
      <Footer />
    </Div>
  );
}

export default App;
