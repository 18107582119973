import styled from 'styled-components';

//assets
import dog from '../assets/landing.webp';

const Landing = () => {

    return (
        <Style>
            <div className='description'>
                
                <h1>Welcome to Oxbutts Pet & Stable Food Supplies</h1>
                <p>The Pet specialist</p>
                <div className='info'>
                    <p>Our new NEW website is coming SOON</p>
                    <p>STAY TUNED!</p>
                </div>
            </div>
            <p className='intro'>
                We are based in Oxbutts Industrial Estate in Bishops Cleeve just outside of Cheltenham and we have been trading since 1981. There is plenty of Free parking and we offer help to load your car too! 
                <br/>We also deliver for FREE to local customers.
            </p>
        </Style>
    )
};

const Style = styled.div`
    color: #ffffff;
    .description{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 90vh;
        position: relative;
        z-index: 0;
        ::after{
            content: '';
            background-image: url(${dog});
            background-size: cover;
            background-repeat: no-repeat;
            background-position: top;
            opacity: 0.9;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            position: absolute;
            z-index: -1; 
        }
        h1{
            text-align: center;
            margin: 0 auto;
            width: 80%;
            font-size: 4em;
        }
        p{
            margin-top: 20px;
            font-size: 1.5rem;
        }
        .info {
            position: absolute;
            top: 10px;
            p{
                text-align: center;
            }
        }
        
    }
    .intro{
        font-size: 1.5rem;
        color: #252929;
        background-color: #9ab83c;
        line-height: 1.5;
        margin: auto;
        text-align: center;
        padding: 20px;
    }

    @media only screen and (max-width: 400px){
        .description{
            h1{
                font-size: 3rem;
            }
        }
    }
`;

export default Landing;