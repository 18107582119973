import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faAt } from '@fortawesome/free-solid-svg-icons';
import { faCopyright } from '@fortawesome/free-regular-svg-icons';

const Footer = () => {

    return (
        <Style>
            <div className='wrapper' >
                <div className='flex address'>
                    <p>Oxbutts Pet & Stable Food Supply</p>
                    <p>Oxbutts Industrial Estate, 1b</p>
                    <p>Station Road</p>
                    <p>Woodmancote</p>
                    <p>Cheltenham</p>
                    <p>GL52 9HW</p>
                </div>
                <div className='flex contact'>
                    <p><FontAwesomeIcon icon={faPhone} /> 01242 675 726</p>
                    <p className='mail'><FontAwesomeIcon icon={faAt} /> trading@oxbuttspet.com</p>
                </div>
                <div className='flex social'>
                    <p>Follow us on Facebook</p>
                    <a href='https://www.facebook.com/oxbuttspets' target='_blank' className='facebook' rel="noreferrer">
                        <FontAwesomeIcon icon={faFacebook} />
                    </a>
                </div>
            </div>
            <div className='rights'>All rights reserved <FontAwesomeIcon icon={faCopyright}/> Oxbutts Pet and Satble Supplies | Designed by GDWebDev</div>
        </Style>
    )
};

const Style = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #9ab83c;
    border-top: solid 1px #e2e4dc;
    display: flex;
    justify-content: center;
   .wrapper{
        width: 80%;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 20px auto;
        .flex{
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 20px 10px 0 10px;
        }
        .contact{
            align-items: center;
            .mail{
                padding-top: 10px;
            }
        }
        .social{
            align-items: center;
        }
        .facebook{
            margin-top: 20px;
            font-size: 40px;
            color: #0d8af1;
        }
   }
   .rights{
       font-size: 13px;
       padding-bottom: 20px;
       text-align: center;
   }

   @media only screen and (max-width: 783px){
        .social:before{
            margin-bottom: 20px;
            display: block;
            content: ' ';
            width: 80%;
            border-top: solid 1px #e2e4dc;
       }
   }

   @media only screen and (max-width: 570px){
       .wrapper{
           flex-direction: column;
           .address{
               align-items: center;
           }
       }
    .contact:before{
           margin-bottom: 20px;
           display: block;
           content: ' ';
           width: 80%;
           border-top: solid 1px #e2e4dc;
       }
   }
`;

export default Footer;